import React from "react";
import imgOne from "../img/banner1.jpg";
import imgTwo from "../img/banner2.jpg";
import imgThree from "../img/banner3.jpg";

function Banner() {
  return (
    <div className="mt-5">
      <div
        id="carouselExampleIndicators"
        className="carousel slide margint"
        style={{marginTop: "80px"}}>
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={0}
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={1}
            aria-label="Slide 2"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={2}
            aria-label="Slide 3"
          />
        </div>
        <div className="carousel-inner">
          <div className="carousel-item">
            <img src={imgThree} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item active">
            <img src={imgOne} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={imgTwo} className="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Banner;
